<template>
  <section class="bg-light">
    <div class="container px-5">
      <div
        class="row gx-5 align-items-center justify-content-center justify-content-lg-between"
      >
        <div class="col-12 col-lg-5">
          <h2 class="display-4 lh-1 mb-4">Πέρνα στη νέα γενιά ταξιδιών</h2>
          <p class="lead fw-normal text-muted mb-5 mb-lg-0">
            Όλοι έχουμε κουραστεί να ταξιδεύουμε μόνοι, μετρώντας τα χρήματα τα
            οποία θα μπορούσαμε να έχουμε εξοικονομήσει αν οι διπλανές μας
            θέσεις ήταν γεμάτες. Τι περιμένεις λοιπόν; Στο Ouride μπορείς να
            ψάξεις, να βρεις και να μοιραστείς το αυτοκίνητό σου με ταξιδιώτες
            που ανυπομονούν να γνωρίσουν όλη τη χώρα. Ταξιδεύεις με παρέα,
            εξοικονομείς χρήματα, προστατεύεις το περιβάλλον και όλα ελεύθερα
            χωρίς περιορισμούς. Η κοινότητα του Ouride σε περιμένει!
          </p>
        </div>
        <div class="col-sm-8 col-md-6">
          <div class="px-5 px-sm-0">
            <img
              class="img-fluid rounded-circle"
              src="../../assets/img/demo-screen2.png"
              alt="..."
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BasicFeature-vue",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
