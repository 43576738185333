<template>
  <footer class="bg-black text-center py-5">
    <div class="container px-5">
      <div class="text-white-50 small">
        <div class="mb-2">&copy; Ouride 2022. All Rights Reserved.</div>
        <a href="https://ouride.gr/termsPolicies/privacy_GR.html">Privacy</a>
        <span class="mx-1">&middot;</span>
        <a href="https://ouride.gr/termsPolicies/terms_GR.html">Terms</a>
        <span class="mx-1">&middot;</span>
        <a href="#!">ouridecommunity@gmail.com</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Strip-vue",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
